<!--海外分红-->
<template>
  <div class="container">
    <div class="content">
      <div v-for="(item,index) in list" :key="item.fundCode+index" class="list">
        <div class="title flex">
          <div class="code-num">{{ item.fundCode||'--' }}</div>
          <div class="name">{{ item.fundName||'--' }}</div>
        </div>
        <div class="flex fund-content">
          <div class="left flex">
            <div class="item">
              <div class="top-text">{{ list1[item.ofundRisklevel]||'--' }}</div>
              <div class="bottom-text">风险等级</div>
            </div>
            <div class="item">
              <div class="top-text worth">{{ item.newestValue||'--' }}</div>
              <div class="bottom-text">{{ item.enddate||'--' }}</div>
            </div>
            <div class="item">
              <div
                class="top-text red"
              >{{ item.changeDay?formatDecimal(accMul(item.changeDay,1),2):'--' }}%</div>
              <div class="bottom-text">日涨跌幅</div>
            </div>
            <div class="item">
              <div
                class="top-text red"
              >{{ item.changeMonth?formatDecimal(accMul(item.changeMonth,1),2):'--' }}%</div>
              <div class="bottom-text">近1月</div>
            </div>
            <div class="item">
              <div
                class="top-text red"
              >{{ item.changeThreeMonth?formatDecimal(accMul(item.changeThreeMonth,1),2):'--' }}%</div>
              <div class="bottom-text">近3月;</div>
            </div>
            <div class="item">
              <div
                class="top-text red"
              >{{ item.changeHalfYear?formatDecimal(accMul(item.changeHalfYear,1),2):'--' }}%</div>
              <div class="bottom-text">近半年</div>
            </div>
            <div class="item">
              <div
                class="top-text red"
              >{{ item.changeYear?formatDecimal(accMul(item.changeYear,1),2):'--' }}%</div>
              <div class="bottom-text">近1年</div>
            </div>
            <div class="item" v-if="false">
              <div
                class="top-text"
              >{{ item.saleFeeRate?formatDecimal(accMul(item.saleFeeRate,100),2) :'--' }}%</div>
              <div class="bottom-text">买入费率</div>
            </div>
          </div>
          <div class="right flex">
            <div class="common buy">购买</div>
            <div class="common sale">定投</div>
          </div>
        </div>
      </div>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="pageNum"
        :limit.sync="pageSize"
        :layout="layout"
        @pagination="getList"
      />
    </div>
  </div>
</template>


<script>
import { accMul, formatDecimal } from '@/utils/debounce.js'
import { achievementLead } from '@/api/achievementLead'
export default {
  name: 'OverseasDividend',
  components: {},
  data() {
    return {
      total: 0,
      pageNum: 1,
      pageSize: 20,
      layout: 'prev, pager, next, sizes',
      list: [],
      list1: ['', '低', '中', '高'],
      //状态分类(业绩领先,海外分红,潜力指数)
      type: this.$route.params.type
    }
  },
  created() {
    this.getList()
  },
  methods: {
    accMul,
    formatDecimal,
    getList() {
      achievementLead({ pageNum: 1, pageSize: 10, type: 1 })
        .then((res) => {
          if (res.code === 200) {
            const { data } = res
            this.list = data.data
            this.total = data.total
            console.log('total', this.total)
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  }
}
</script>


<style lang="scss" scoped>
.container {
  background: #f3f4f6
    url('../../../static/img/selected-list/overseas-dividend.svg') no-repeat;
  background-size: 100%;
}
.content {
  width: 1200px;
  padding-top: 167px;
  margin: 0 auto;
  padding-bottom: 40px;
}

.red {
  color: #c5231c;
}
.worth {
  color: #25293d;
}
/* 列表项 */
.list {
  margin-top: 8px;
  padding: 36px 30px 31px;
  background: #fff;
  box-shadow: 0px 4px 12px #edf1f7;
  // 内容文字包裹
  .name {
    text-align: center;
  }
  .top-text {
    font-size: 16px;
    line-height: 16px;
    color: #a5a5a5;
  }
  .bottom-text {
    margin-top: 5px;
    font-weight: 290;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #25293d;
  }

  // 基金标题
  .title {
    margin-bottom: 24px;

    .code-num {
      font-weight: 300;
      font-size: 18px;
      line-height: 18px;
      color: #ce9b63;
    }
    .name {
      margin-left: 14px;
      font-weight: 290;
      font-size: 20px;
      line-height: 20px;
      color: #1f1f1f;
    }
  }

  // 基金内容
  .fund-content {
    justify-content: space-between;
    .common {
      padding: 7px 50px;
      border-radius: 2px;
      font-size: 14px;
      line-height: 15px;
      text-align: center;
      cursor: pointer;
    }
    // 购买
    .buy {
      color: #fff;
      background: #deb87b;
    }
    // 定投
    .sale {
      margin-left: 14px;
      background: #ffffff;
      border: 0.68px solid #ce9b63;
      color: #b88141;
    }

    // 左侧内容
    .left {
      flex: 1;
    }

    .right {
      margin-left: 83px;
    }
    .item {
      flex: 1;
      text-align: center;
    }
  }
}
</style>
